import React from 'react'

const HeaderBook = props => (
  <header id="header">
    <h1>Book Now</h1>
    <p>Thanks for showing interest!</p>
  </header>
)

export default HeaderBook
